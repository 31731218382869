import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Energyze from "../../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={'EN'}>
      <Seo title="Hydrating and oxygenating face wash sorbet - Energyze"  lang={'EN'}/>
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1>Hydrating and oxygenating face wash sorbet</H1>
                    <p>Cleansing & Oxygen Boost</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Hydrating and oxygenating face wash sorbet"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Deeply cleanses.</li>
                        <li>Hydrates and works to lock in moisture to prevent skin dryness. </li>
                        <li>Deeply nourishes and conditions to awake the skin. </li>
                        <li> 
                        Oxygenates to give skin cells boost to allow them do what they do best. 
                        </li>
                    </ul>
                    <Container>
                        <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml-000000000000407511.html" color="lime">Buy in Hebe</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml.html" color="lime">Buy in Ziko Dermo</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml-0107786" color="lime">Buy in Gemini</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176925-Oxygenetic_Energyze_Vitamin_C_nawilzajaco-dotleniajacy_sorbet_do_mycia_twrazy_75_ml" color="lime">Buy in DOZ</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="nawilzajacy-sorbet"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                            src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="regeneracyjne serum"
                        />
                    </Link>
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">

                            <div className="text--left">
                                <p>
                                    <strong>Oxygenetic hydrating and oxygenating face wash sorbet  </strong>
                                </p>
                                <p>
                                    This sorbet-scrub cleanser will gently exfoliate your skin with almond shell powder for smooth, radiant effect and for better absorption of actives. 
                                </p>
                                <p>
                                Rich in vitamins and minerals deeply hydrates and locks in moisture to prevent Transepidermal Water Loss (TEWL) for  nourished and beautiful skin. 
                                </p>
                                <p>
                                With this cleanser your complexion will look revived and healthier. The sorbet formula is super gentle for the skin, removes unpleasant tightness for soft and smooth effect. Use this sorbet cleanser if you want to enjoy deeply cleansed, smoothed and hydrated skin. 

                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/nawilzajacy-sorbet_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="nawilzajacy-sorbet"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong> How to use:</strong></p>
                                <p>Apply in the morning or evening into the moist skin of your face. Massage with gentle circular moves and then wash off.  For external use only. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>75ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Vitamin C" desc="A powerhouse antioxidant which neutralises free radicals harmful to skin cells. Slows aging process in the body and shields against environmental stressors. Lightens pigementation and revives the skin due to enhanced collagen and elastin synthesis." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Almond shell powder" desc="exfoliates gently to remove dead skin cells, dirt and and impurities for renewed, smoother and brightened skin." />
                    <ActiveIngredient name="Cucumber extract" desc="a valuable source of vitamins and minerals. Locks in moisture and reduces Transepidermal Water Loss (TEWL). Hydrating antioxidant to refresh and delay aging." />
                    <ActiveIngredient name="Olive milk" desc="a rich source of lipids and proteins which coat the skin to protect against moisture loss, plus has antioxidating, repair and antibacterial effect. " />
                    <ActiveIngredient name="Avocado oil" desc="fortifies skin barrier  to prevent dehydration for deeply nourished, rejuvenated and healthy complexion, naturally." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">

                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że przy regularnym stosowaniu skóra odzyskuje zdrowy i promienny wygląd"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że produkt delikatnie złuszcza nie powodując podrażnień"/>
                    <EffectivnessCard color="lime" percentage="90" desc="badanych potwierdza, że produkt zmniejsza widoczność porów"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
